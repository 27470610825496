import axios from "axios";

export default {
    /**
     *
     * @param customerId
     * @returns customer commesse list
     * ? Used in NuovoRapportino to get commesse for selected customer
     */
    async getCommesseCliente(customerId: string) {
        const data = new FormData();
        data.append("where[]", `projects_customer_id = ${customerId}`);

        const response = await axios.post("rest/v1/search/projects", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data;
    },

    /**
     *
     * @param commessaID
     * @returns services for selected commessa
     * ? Used in ProgrammaLavoroDetail
     */
    async getCommessaServizi(commessaID: string) {
        const response = await axios.get(`rest/v1/view/projects/${commessaID}`, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    /**
     *
     * @param commessaID
     * @returns services for selected commessa
     * ? Used in ProgrammaLavoroDetail
     */
    async getProjectDocuments(commessaID: string) {
        const data = new FormData();
        data.append("where[]", `project_attachments_project_id = ${commessaID}`);
        data.append("where[]", `project_attachments_type = '2'`);
        const response = await axios.post(`rest/v1/search/project_attachments`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    /**
     *
     * @param commessaID
     * @returns services for selected commessa
     * ? Used in ProgrammaLavoroDetail
     */
    async getFilteredServizi(commessaID: string, nGiorno: string) {
        const data = new FormData();
        data.append("where[]", `gestione_servizi_commessa_id = '${commessaID}'`);
        data.append("where[]", `gestione_servizi_giorno_id = '${nGiorno}'`);

        const response = await axios.post(`rest/v1/search/gestione_servizi`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    },
};
